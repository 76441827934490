import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import Swal from 'sweetalert2';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useTranslation } from 'react-i18next';


const NewCustomer = () => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
const [selectedFile, setSelectedFile] = useState(null);
const [uploadLoading, setUploadLoading] = useState(false);
  const [searchValues, setSearchValues] = useState({
    name: '',
    phone_number: ''
  });
  const [searchParams, setSearchParams] = useState({
    name: '',
    phone_number: ''
  });
  const [totalPages, setTotalPages] = useState(0);

  const [editCustomer, setEditCustomer] = useState(null);

  const fetchCustomers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${config.apiBaseUrl}/api/customers/getCustomer`, {
        params: {
          page: currentPage,
          name: searchParams.name,
          phone_number: searchParams.phone_number,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (response.data.status === 'success') {
        setCustomers(response.data.data.customers);
        setTotalPages(response.data.data.pagination.totalPages);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      Swal.fire({
        title: "Error",
        text: "Failed to fetch customers",
        icon: "error",
      });
      if (error.response?.status === 401) {
        localStorage.removeItem("token");
      }
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchParams, setCustomers, setTotalPages, setLoading]);
  

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers, currentPage, searchParams]);


  const handleImageClick = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalImage(null);
    setIsModalOpen(false);
  };



  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
      handleImageUpload(file);
    } else {
      Swal.fire({
        title: "Error",
        text: "Please select a valid image file (JPEG or PNG)",
        icon: "error"
      });
    }
  };

  const handleImageUpload = async (file) => {
    if (!file) return;
  
    try {
      setUploadLoading(true);
      const storage = getStorage();
    const storageRef = ref(storage, `/govt-ids/${file.name}`);

      
      // Upload the file
      const snapshot = await uploadBytes(storageRef, file);
      
      // Get the download URL
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      // Update the customer object with new image URL
      editCustomer.govt_id_image = downloadURL;
      // setSelectedFile(null);
      Swal.fire({
        title: "Success",
        text: "Government ID updated successfully",
        icon: "success"
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      Swal.fire({
        title: "Error",
        text: "Failed to upload image",
        icon: "error"
      });
    } finally {
      setUploadLoading(false);
    }
  };
  
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchParams(searchValues);
  };

  const handleReset = () => {
    setSearchValues({ name: '', phone_number: '' });
    setSearchParams({ name: '', phone_number: '' });
    setCurrentPage(1);
  };

  const handleEditCustomer = (customer) => {
    setEditCustomer(customer);
  };

  const handleUpdateCustomer = async (e) => {
    e.preventDefault();
    try {
      // Prepare the update data according to API requirements
      const updateData = {
        name: editCustomer.name,
        home_address: editCustomer.home_address,
        govt_id_pic: editCustomer.govt_id_image // Using the existing or newly uploaded image URL
      };
  
      const response = await axios.put(
        `${config.apiBaseUrl}/api/customers/${editCustomer.phone_number}`, // Using phone number as param
        updateData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
  
      if (response.data.success) {
        Swal.fire({
          title: "Success",
          text: "Customer updated successfully",
          icon: "success",
          confirmButtonColor: '#3085d6'
        });
        
        // Clean up states
        setEditCustomer(null);
        setSelectedFile(null);
        
        // Refresh the customers list
        fetchCustomers();
      }
    } catch (error) {
      console.error('Error updating customer:', error);
      
      // Handle different error cases
      if (error.response?.status === 404) {
        Swal.fire({
          title: "Error",
          text: "Customer not found",
          icon: "error"
        });
      } else if (error.response?.status === 400) {
        Swal.fire({
          title: "Error",
          text: "Invalid government ID image URL provided",
          icon: "error"
        });
      } else {
        Swal.fire({
          title: "Error",
          text: error.response?.data?.message || "Failed to update customer",
          icon: "error"
        });
      }
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">{t('Customer List')}</h1>

      {/* Search Section */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <form onSubmit={handleSearch} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
              <input
                type="text"
                placeholder="Search by name..."
                value={searchValues.name}
                onChange={(e) => setSearchValues(prev => ({ ...prev, name: e.target.value }))}
                className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
              <input
                type="text"
                placeholder="Search by phone number..."
                value={searchValues.phone_number}
                onChange={(e) => setSearchValues(prev => ({ ...prev, phone_number: e.target.value }))}
                className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={handleReset}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
            >
              Reset
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 border border-transparent rounded-md text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none"
            >
              Search
            </button>
          </div>
        </form>
      </div>

      {/* Customer List */}
      {/* Customer List - Mobile Responsive */}
<div className="bg-white shadow-lg rounded-lg overflow-hidden">
  {loading ? (
    <div className="text-center py-4">Loading...</div>
  ) : customers.length === 0 ? (
    <div className="text-center py-4 text-gray-500">No customers found</div>
  ) : (
    <div className="overflow-x-auto">
      {/* For larger screens */}
      <table className="min-w-full divide-y divide-gray-200 hidden md:table">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {customers.map((customer) => (
            <tr key={customer.customer_id}>
              <td className="px-6 py-4 whitespace-nowrap">{customer.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{customer.phone_number}</td>
              <td className="px-6 py-4 whitespace-nowrap">{customer.home_address}</td>
              <td className="px-6 py-4 whitespace-nowrap">{formatDate(customer.created_at)}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  onClick={() => handleEditCustomer(customer)}
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* For mobile screens */}
      <div className="md:hidden">
        {customers.map((customer) => (
          <div key={customer.customer_id} className="border-b border-gray-200 p-4">
            <div className="flex justify-between items-start mb-2">
              <div className="font-medium text-gray-900">{customer.name}</div>
              <button
                onClick={() => handleEditCustomer(customer)}
                className="text-indigo-600 hover:text-indigo-900 text-sm"
              >
                Edit
              </button>
            </div>
            <div className="space-y-1 text-sm">
              <div className="text-gray-500">
                <span className="font-medium">Phone:</span> {customer.phone_number}
              </div>
              <div className="text-gray-500">
                <span className="font-medium">Address:</span> {customer.home_address}
              </div>
              <div className="text-gray-500">
                <span className="font-medium">Created:</span> {formatDate(customer.created_at)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )}

  {/* Pagination - Made responsive */}
  {customers.length > 0 && (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200">
      <div className="flex justify-between w-full gap-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`relative flex-1 md:flex-none inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
            currentPage === 1
              ? 'bg-gray-100 text-gray-400'
              : 'bg-white text-gray-700 hover:bg-gray-50'
          }`}
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage((prev) => prev + 1)}
          disabled={currentPage === totalPages}
          className={`relative flex-1 md:flex-none inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
            currentPage === totalPages
              ? 'bg-gray-100 text-gray-400'
              : 'bg-white text-gray-700 hover:bg-gray-50'
          }`}
        >
          Next
        </button>
      </div>
    </div>
  )}
</div>
{/* Edit Dialog */}
{editCustomer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Edit Customer</h2>
            <form onSubmit={handleUpdateCustomer}>
            <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
          <input
            type="text"
            value={editCustomer.name}
            onChange={(e) => setEditCustomer({ ...editCustomer, name: e.target.value })}
            className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-indigo-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
          <input
            type="text"
            value={editCustomer.phone_number}
            onChange={(e) => setEditCustomer({ ...editCustomer, phone_number: e.target.value })}
            className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-indigo-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Home Address</label>
          <textarea
            value={editCustomer.home_address}
            onChange={(e) => setEditCustomer({ ...editCustomer, home_address: e.target.value })}
            className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-indigo-500"
            rows="3"
            required
          />
        </div>
              {editCustomer.govt_id_image && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Government ID</label>
                  <div className="flex items-center space-x-4">
                    <img
                      src={selectedFile ? URL.createObjectURL(selectedFile) : editCustomer.govt_id_image}
                      alt="Government ID"
                      className="w-32 h-32 object-cover border border-gray-300 rounded cursor-pointer"
                      onClick={() => handleImageClick(selectedFile ? URL.createObjectURL(selectedFile) : editCustomer.govt_id_image)}
                    />
                    <input
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={handleFileSelect}
                      
                      className="text-sm text-gray-600"
                    />
                  </div>
                </div>
              )}

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => {
                    setEditCustomer(null);
                    setSelectedFile(null);
                  }}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                >
                  Cancel
                </button>
                <button
  type="submit"
  className={`px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white 
    ${uploadLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'}`}
  disabled={uploadLoading} // Disable the button if uploadLoading is true
>
  Save Changes
</button>

              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal for Full-Size Image */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative bg-white rounded-lg p-4 shadow-lg">
            <button
              className="absolute top-2 right-2 text-gray-700 bg-gray-200 rounded-full p-2 hover:bg-gray-300"
              onClick={closeModal}
            >
              ✕
            </button>
            <img
              src={modalImage}
              alt="Full Size"
              className="w-auto max-w-full h-auto max-h-[90vh] rounded-md"
            />
          </div>
        </div>
      )}

                    </div>
                  );
                };
                
                export default NewCustomer;
                