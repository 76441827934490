import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../config";

const ReturnRental = () => {
  const [plateNumber, setPlateNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [scooterDetails, setScooterDetails] = useState([]);
  const [customerDetails, setCustomerDetails] = useState(null);
  const token = localStorage.getItem("token");
  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/orders/getOrder`,
        { plate_number: plateNumber },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setStartTime(response.data.start_date);
        setOrderId(response.data.orderId);
        setTotalAmount(response.data.total_Amount);
        setScooterDetails(response.data.scooters);

        const customerResponse = await axios.post(
          `${config.apiBaseUrl}/api/orders/getCustomerOrder`,
          { orderId: response.data.orderId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCustomerDetails(customerResponse.data.customer);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        Swal.fire({
          title: "Order Not Found",
          text: "No order found with the entered plate number.",
          icon: "error",
        });
      } else {
        console.error("Error fetching details:", error);
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred while fetching order details.",
          icon: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      // Check if all scooters are unrented (status not "rented")
      const allUnrented = scooterDetails.every(
        (scooter) => scooter.status !== "rented"
      );

      const updatedScooters = scooterDetails.map((scooter) => ({
        order_item_id: scooter.order_item_id,
        scooter_id: scooter.scooter_id,
        status: scooter.status,
        condition: scooter.condition_at_return || "", // Ensure condition is not undefined
      }));

      const payload = {
        order_id: orderId, // Ensure this value is retrieved properly
        order_items: updatedScooters,
        total_amount: totalAmount,
        status: allUnrented ? "completed" : "active", // Decide status based on scooter states
      };

      // Call the close order API if all scooters are unrented
      const endpoint = `${config.apiBaseUrl}/api/orders/closeOrder`;

      const response = await axios.post(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          title: "Order Updated",
          text: allUnrented
            ? "Order has been successfully completed!"
            : "Order details have been updated!",
          icon: "success",
        });
        handleReset();
      } else {
        console.error("Error updating order:", response.data);
        alert("Failed to update order. Please try again.");
      }
    } catch (error) {
      console.error("Error updating order:", error);
      alert("An error occurred while updating the order.");
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setPlateNumber("");
    setScooterDetails([]);
    setCustomerDetails(null);
  };

  return (
    <div className="return-rental max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-10">
      <h2 className="text-2xl font-bold mb-6 text-center text-blue-700">
        Return Rented Scooter
      </h2>
      <div className="search-section flex gap-4 mb-8">
        <input
          type="text"
          placeholder="Enter Rented Scooter No."
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          value={plateNumber}
          onChange={(e) => setPlateNumber(e.target.value)}
        />
        <button
          onClick={handleSearch}
          className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Search
        </button>
      </div>

      {loading && <div className="spinner text-center text-lg">Loading...</div>}

      {!loading && scooterDetails.length > 0 && (
        <div>
          <h3 className="text-xl font-semibold mb-4 text-gray-700">
            Scooter Details
          </h3>
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr>
                <th className="py-3 px-4 border-b text-left text-gray-600">
                  Scooter Plate Number
                </th>
                <th className="py-3 px-4 border-b text-left text-gray-600">
                  Status
                </th>
                <th className="py-3 px-4 border-b text-left text-gray-600">
                  Condition
                </th>
              </tr>
            </thead>
            <tbody>
              {scooterDetails.map((scooter) => (
                <tr
                  key={scooter.scooter_id}
                  className="border-t hover:bg-gray-50"
                >
                  <td className="py-3 px-4">{scooter.plate_number}</td>
                  <td className="py-3 px-4">
                    <select
                      value={scooter.status}
                      onChange={(e) => {
                        const newStatus = e.target.value;
                        setScooterDetails((prevDetails) =>
                          prevDetails.map((item) =>
                            item.scooter_id === scooter.scooter_id
                              ? {
                                  ...item,
                                  status: newStatus,
                                  condition_at_return:
                                    newStatus === "rented"
                                      ? ""
                                      : item.condition_at_return,
                                }
                              : item
                          )
                        );
                      }}
                      className="p-2 border border-gray-300 rounded"
                    >
                      <option value="rented">Rented</option>
                      <option value="available">Available</option>
                      <option value="sold">Sold</option>
                      <option value="maintenance">Broken</option>
                    </select>
                  </td>
                  <td className="py-3 px-4">
                    <input
                      type="text"
                      disabled={scooter.status === "rented"}
                      value={
                        scooter.status === "rented"
                          ? ""
                          : scooter.condition_at_return || ""
                      }
                      onChange={(e) =>
                        setScooterDetails((prevDetails) =>
                          prevDetails.map((item) =>
                            item.scooter_id === scooter.scooter_id
                              ? { ...item, condition_at_return: e.target.value }
                              : item
                          )
                        )
                      }
                      className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:bg-gray-100"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3 className="text-xl font-semibold mt-8 mb-4 text-gray-700">
            Customer Details
          </h3>
          <div className="customer-details bg-gray-50 p-4 rounded-lg shadow-md space-y-3">
            <p>
              <strong>Customer Name:</strong> {customerDetails?.name}
            </p>
            <p>
              <strong>Phone Number:</strong> {customerDetails?.phoneNumber}
            </p>
            <p>
              <strong>Address:</strong> {customerDetails?.homeAddress}
            </p>
            <p>
              <strong>Government ID:</strong>
              <img
                src={customerDetails?.govtIdImage}
                alt="Govt ID"
                width="250"
                className="mt-2"
              />
            </p>
            <p>
              <strong>Date of Transaction: </strong>
              {new Date(startTime).toLocaleString()}
            </p>
          </div>

          <h3 className="text-xl font-semibold mt-8 mb-4 text-gray-700">
            Payment Details
          </h3>
          <div className="payment-details mb-8 flex items-center">
            <label className="text-gray-700 font-semibold mr-4">
              Total Amount:
            </label>
            <input
              type="number"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
              className="p-2 border border-gray-300 rounded-lg w-32 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          <div className="actions mt-8 flex gap-4">
            <button
              onClick={handleUpdate}
              className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition"
            >
              Update Order
            </button>
            <button
              onClick={handleReset}
              className="px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition"
            >
              Reset
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReturnRental;
