import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "./component/Sidebar";
import Login from "./component/Login";
import { useTranslation } from 'react-i18next';
import './i18n';
import Dashboard from "./component/Dashboard";
import NewScooter from "./component/NewScooter";
import NewRental from "./component/NewRental";
import ScooterList from "./component/ScooterList";
import ReturnRental from "./component/ReturnRental";
import NewCustomer from "./component/NewCustomer";
import PastRentals from "./component/PastRentals";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="fixed top-4 right-4 z-50 flex items-center space-x-2 bg-white rounded-lg shadow-md px-4 py-2 text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span>{i18n.language === 'en' ? '🇺🇸' : '🇮🇱'}</span>
        <span>{i18n.language === 'en' ? 'English' : 'עברית'}</span>
        <svg 
          className={`w-4 h-4 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      
      {isOpen && (
        <div className="fixed top-16 right-4 z-50 bg-white rounded-lg shadow-lg overflow-hidden">
          <button 
            onClick={() => {
              i18n.changeLanguage('en');
              setIsOpen(false);
            }}
            className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-2"
          >
            <span>🇺🇸</span>
            <span>English</span>
          </button>
          <button 
            onClick={() => {
              i18n.changeLanguage('he');
              setIsOpen(false);
            }}
            className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-2"
          >
            <span>🇮🇱</span>
            <span>עברית</span>
          </button>
        </div>
      )}
    </div>
  );
};

const ProtectedRoute = ({ children }) => {
  const accessToken = localStorage.getItem('token');
  if (!accessToken) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const Layout = ({ children, setIsAuthenticated }) => {  // Add setIsAuthenticated as a prop
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <div
        className={`${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } fixed z-30 inset-y-0 left-0 w-64 bg-indigo-900 text-white transform lg:relative lg:translate-x-0 transition-transform duration-200 ease-in-out`}
      >
        {/* Pass setIsAuthenticated to Sidebar */}
        <Sidebar closeSidebar={closeSidebar} setIsAuthenticated={setIsAuthenticated} />
      </div>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 lg:hidden z-20"
          onClick={closeSidebar}
        ></div>
      )}

      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="flex items-center justify-between bg-white shadow-lg p-4 lg:hidden">
          <button onClick={toggleSidebar} className="text-gray-500 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </header>

        <main className="flex-1 overflow-y-auto p-6 bg-gray-100">
          {children}
        </main>
      </div>
    </div>
  );
};


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('token');
    setIsAuthenticated(!!accessToken);  // Only set once on mount
  }, []);

  return (
    <Router>
      <LanguageSwitcher />
      <Routes>
        <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />} />
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <Layout setIsAuthenticated={setIsAuthenticated}> {/* Pass setIsAuthenticated to Layout */}
              <Dashboard />
            </Layout>
          </ProtectedRoute>
        } />
        <Route path="/new-scooter" element={
          <ProtectedRoute>
            <Layout setIsAuthenticated={setIsAuthenticated}> {/* Pass setIsAuthenticated to Layout */}
              <NewScooter />
            </Layout>  
          </ProtectedRoute>
        } />
        <Route path="/new-rent" element={
          <ProtectedRoute>
            <Layout setIsAuthenticated={setIsAuthenticated}> {/* Pass setIsAuthenticated to Layout */}
              <NewRental />
            </Layout>  
          </ProtectedRoute>
        } />

<Route path="/scooter-list" element={
          <ProtectedRoute>
            <Layout setIsAuthenticated={setIsAuthenticated}> {/* Pass setIsAuthenticated to Layout */}
              <ScooterList />
            </Layout>  
          </ProtectedRoute>
        } />


<Route path="/return-rent" element={
          <ProtectedRoute>
            <Layout setIsAuthenticated={setIsAuthenticated}> {/* Pass setIsAuthenticated to Layout */}
              <ReturnRental />
            </Layout>  
          </ProtectedRoute>
        } />

<Route path="/customer-list" element={
          <ProtectedRoute>
            <Layout setIsAuthenticated={setIsAuthenticated}> {/* Pass setIsAuthenticated to Layout */}
              <NewCustomer />
            </Layout>  
          </ProtectedRoute>
        } />
        <Route path="/past-rented" element={
          <ProtectedRoute>
            <Layout setIsAuthenticated={setIsAuthenticated}> {/* Pass setIsAuthenticated to Layout */}
              <PastRentals />
            </Layout>  
          </ProtectedRoute>
        } />

      </Routes>
    </Router>
  );
}


export default App;
