import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import config from '../config';
import SignatureCanvas from 'react-signature-canvas';
import { v4 as uuidv4 } from 'uuid'; 
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import imageCompression from 'browser-image-compression';
import { auth } from '../firebase';
import Swal from 'sweetalert2';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';

const NewRental = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const token = localStorage.getItem('token');
  const [signature, setSignature] = useState(null);
  const signatureCanvasRef = useRef(null);
  const [error, setError] = useState('');


  const [scooterCount, setScooterCount] = useState(1);
  const [customer, setCustomer] = useState(''); 
  const [customerMobile, setCustomerMobile] = useState('+97');
  const [otpSent, setOtpSent] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);

  const [chooseCount, setChooseCount] = useState(true);
  const [otp, setOtp] = useState('');
  const recaptchaVerifier = useRef(null);
  const recaptchaContainerRef = useRef(null);

  const [scooterFields, setScooterFields] = useState([{ scooter_id: '', number: '' }]);
  const [availableScooters, setAvailableScooters] = useState([]);
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [orderSaved, setOrderSaved] = useState(false);

  const [customerDetails, setCustomerDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newCustomer, setNewCustomer] = useState({
    name: '',
    home_address: '',
    govt_id_pic: '',
    phone_number: '+97',
  });

  const uploadGovtIdToFirebase = async (file, customerMobile) => {
    const storage = getStorage();
    const storageRef = ref(storage, `/govt-ids/${file.name}`);
    
    try {
        // Upload the file to Firebase Storage
        const snapshot = await uploadBytes(storageRef, file);
        
        // Get the URL of the uploaded file
        return await getDownloadURL(snapshot.ref);
    } catch (error) {
        console.error("Error uploading image to Firebase:", error);
        throw error;
    }
};

  const handleTermsChange = () => {
    if (!termsAccepted) {
      setShowTermsModal(true);
    } else {
      setTermsAccepted(false);
    }
  };

  const handleSignature = () => {
    setSignature(signatureCanvasRef.current.toDataURL('image/png'));
    setTermsAccepted(true);
    setShowTermsModal(false);
  };

  useEffect(() => {
    if (recaptchaVerifier.current) {
      recaptchaVerifier.current.clear();
    }
  
    const fetchScooters = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api/scooters/available`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        // Assuming each scooter object contains `scooter_id` and `number`
        const scooters = response.data.available_scooters || [];
        setAvailableScooters(
          scooters.map(scooter => ({
            scooter_id: scooter.scooter_id,
            name: scooter.number
          }))
        );
  
        console.log('Available scooters:', scooters); // Debug log
  
      } catch (error) {
        console.error('Error fetching scooters:', error);
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      }
    };
  
    fetchScooters();
  }, [token]);
  
  

  const handleScooterCountChange = (e) => {
    const count = parseInt(e.target.value);
    setScooterCount(count);
    // const newFields = Array.from({ length: count }, () => ({ number: '' }));
    setScooterFields(Array.from({ length: count }, () => ({ scooter_id: '', number: '' })));
    // setScooterFields(newFields);
  };

  const setupRecaptcha = () => {
    if (!recaptchaVerifier.current && recaptchaContainerRef.current) {
      recaptchaVerifier.current = new RecaptchaVerifier(recaptchaContainerRef.current, {
        size: 'invisible',
        callback: () => {
          console.log('Recaptcha verified');
        },
        'expired-callback': () => {
          console.log('Recaptcha expired');
          if (recaptchaVerifier.current) {
            recaptchaVerifier.current.reset();
          }
        },
      }, auth);
    }
  };

  const sendOtp = async () => {
    try {
      setupRecaptcha();
      if (!recaptchaVerifier.current) {
        throw new Error('Recaptcha not initialized');
      }
      const formattedPhoneNumber = `+${customerMobile}`; // Ensure the phone number is in E.164 format
      const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, recaptchaVerifier.current);
      setVerificationId(confirmationResult.verificationId);
      setOtpSent(true);
      console.log('OTP sent successfully');
    } catch (error) {
      console.error('Error sending OTP:', error);
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
      if (recaptchaVerifier.current) {
        recaptchaVerifier.current.clear();
      }
      recaptchaVerifier.current = null;
    }
  };

  const verifyOtp = async () => {
    if (otp.length === 6 && verificationId) {
      console.log("verifictation happening");
      try {
        const credential = PhoneAuthProvider.credential(verificationId, otp);
        await signInWithCredential(auth, credential);
        setOtpVerified(true);
        console.log('OTP verified successfully');
        setOtpSent(false);
        handleSubmit();
      } catch (error) {
        console.error('Invalid OTP:', error);
        // Swal.fire({
        //   title: "Error",
        //   text: error,
        //   icon: "error",
        // });
      }
    } 
  };


  const handleMobileChange = (e) => {
    setCustomerMobile(e.target.value);
    setNewCustomer({
      name: '',
      home_address: '',
      govt_id_pic: '',
      phone_number: '+97',
    });
    if (recaptchaVerifier.current) {
      recaptchaVerifier.current.clear();
    }
    setCustomerDetails(null);
    setShowCustomerForm(false);
  };

  const checkCustomer = async () => {
    try {
      const response = await axios.post(`${config.apiBaseUrl}/api/customers/check/${customerMobile}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        const { name, home_address, govt_id_image, phone_number, customer_id } = response.data.customer;
        // Set customer ID in state
        setCustomer(customer_id);

        setCustomerDetails(response.data);
        
        setNewCustomer({
          name: name || '',
          home_address: home_address || '',
          govt_id_pic: govt_id_image || '', // Use URL directly
          phone_number: phone_number || customerMobile,
        });
        setShowCustomerForm(true);
        setOtpSent(false);
        setOtpVerified(true);
      
      } else if(response.status === 404) {
        setNewCustomer({
          name: '',
          home_address: '',
          govt_id_pic: '',
          phone_number: customerMobile,
        });
        setShowCustomerForm(true);
        await sendOtp();
      }
    } catch (error) {
      console.error('Customer not found, entering new customer details.', error);
      setShowCustomerForm(true);
      await sendOtp();
    }
    setChooseCount(false);

    console.log(otpSent);
  };

  const insertOrUpdateCustomer = async () => {
    if (otpVerified) {
      try {
        let response;
  
        if (customerDetails) {
          // Update existing customer
          response = await axios.put(
            `${config.apiBaseUrl}/api/customers/${customerMobile}`,
            newCustomer,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            }
          );
        } else {
          // Insert new customer
          response = await axios.post(
            `${config.apiBaseUrl}/api/customers/add`,
            {
              ...newCustomer,
              phone_number: customerMobile,
            },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            }
          );
        }
  
        if (response && response.data && response.data.customer_id) {
          console.log("Customer inserted/updated successfully", response);
          setCustomer(response.data.customer_id); // Set customer_id
        return response.data.customer_id; // Return customer ID
        }
      } catch (error) {
        console.error("Error inserting/updating customer:", error);
        Swal.fire({
          title: "Error",
          text: error.message || "Failed to insert/update customer",
          icon: "error",
        });
        return null; // Indicate failure
      }
    }
    return null; // If OTP not verified or other issues
  };


  // const handleGovtIdPicUpload = async (e) => {
  //   const file = e.target.files[0];

  //   const options = {
  //     maxSizeMB: 3, // Higher size limit to retain quality
  //     maxWidthOrHeight: 400, // Increased dimensions for clearer image
  //     useWebWorker: true,
  //     initialQuality: 0.8 // Higher quality setting
  //   };
    

  //   try {
  //     const compressedFile = await imageCompression(file, options);
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       setNewCustomer({ ...newCustomer, govt_id_pic: reader.result });
  //     };

  //     reader.readAsDataURL(compressedFile);
  //   } catch (error) {
  //     console.error('Error compressing image:', error);
  //     Swal.fire({
  //       title: "Error",
  //       text: error,
  //       icon: "error",
  //     });
  //   }
  // };

const handleGovtIdPicUpload = async (e) => {
  const file = e.target.files[0];
  const lastFiveDigits = customerMobile.slice(-5); // Get the last 5 digits of the mobile number
  const uniqueFileName = `${uuidv4()}_${lastFiveDigits}.${file.name.split('.').pop()}`; // Generate unique filename

  const options = {
    maxSizeMB: 5, // Allow up to 6 MB for larger image sizes
    maxWidthOrHeight: 800, // Increase dimensions for higher resolution
    useWebWorker: true,
    initialQuality: 1 // Set close to 1 for better quality
  };

  try {
    const compressedFile = await imageCompression(file, options);
    
    // Create a new file with the unique name
    const renamedFile = new File([compressedFile], uniqueFileName, { type: compressedFile.type });
    
    const imageUrl = await uploadGovtIdToFirebase(renamedFile, customerMobile);

    setNewCustomer({ ...newCustomer, govt_id_pic: imageUrl });
  } catch (error) {
    console.error('Error uploading image to Firebase:', error);
    Swal.fire({
      title: "Error",
      text: "Failed to upload ID image",
      icon: "error",
    });
  }
};


  const handleScooterSelect = (index, item) => {
    setScooterFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index] = { scooter_id: item.scooter_id, number: item.name };
      return updatedFields;
    });
  };
  

  const saveOrder = async (customerId, signUrl) => {
    const orderData = {
      customer_id: customerId,
      scooterItems: scooterFields.map((field) => ({ scooter_id: field.scooter_id })), // Map scooter IDs as expected
      status: 'active', 
      sign_url: signUrl
    };
  console.log(customer);
    try {
      const response = await axios.post(`${config.apiBaseUrl}/api/orders/create`, orderData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.status === 201) {
        setOrderSaved(true);
        Swal.fire({
          title: "Scooter Rented",
          text: "New scooter rented successfully!",
          icon: "success",
        }).then(() => {
          handleReset(); // Call the handleReset function on OK
        });
      } else {
        Swal.fire({
          title: "Unexpected Status",
          text: `Received status code: ${response.status}`,
          icon: "info",
        });
      }
    } catch (error) {
      if (error.response) {
        // Error with specific response status
        if (error.response.status === 400) {
          Swal.fire({
            title: "Order Error",
            text: error.response.data.error || "Some scooters are not available for rent.",
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: `Error ${error.response.status}: ${error.response.data.error || "Failed to save order"}`,
            icon: "error",
          });
        }
      } else {
        // General error
        Swal.fire({
          title: "Error",
          text: "Failed to save order. Please try again later.",
          icon: "error",
        });
      }
    }
  };

  const uploadSignature = async () => {
    setError('');
    
    try {

      const storage = getStorage();
      // const signatureDataUrl = signatureCanvasRef.current
      //   .toDataURL('');
      
      const blob = await fetch(signature).then((res) => res.blob());
      const storageRef = ref(storage, `signatures/${Date.now()}.png`);

      await uploadBytes(storageRef, blob);
      const signUrl = await getDownloadURL(storageRef);
      // console.log('Signature uploaded successfully, URL:', signUrl);
      return signUrl;
    } catch (error) {
      const errorMessage = error.message || 'Error uploading signature';
      setError(errorMessage);
      console.error('Error uploading signature: ', error);
      throw error;
    }
  };

  
  const handleSubmit = async () => {
    if (otpSent) {
      console.log("Checking the verify OTP");
      await verifyOtp(); // Ensure OTP verification is awaited
    }
  
    setLoading(true);
    console.log("Starting insert/update customer");
  
    // Capture the returned customer ID directly
    const customerInserted = await insertOrUpdateCustomer(); // Wait until customer is inserted or updated
    // console.log(customerInserted);
  
    if (customerInserted) {
      console.log("Proceeding to upload signature");
    
    // Upload the signature and get the sign URL
    const signUrl = await uploadSignature(); // Assuming uploadSignature is a function you create to handle uploading

    console.log("Proceeding to save order");

      await saveOrder(customerInserted, signUrl); // Pass the customer ID to saveOrder
    } else {
      console.error("Customer insert/update failed, order not saved");
    }
  
    setLoading(false);
  };

  const handleResetSign = () =>{
    setSignature(null);
    setTermsAccepted(false);
    signatureCanvasRef.current.clear();
  };
  const handleReset = async () => {
    window.location.reload(); // Reloads the page
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      {loading && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <svg
              className="animate-spin h-10 w-10 text-white mb-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            <p className="text-white text-lg">Saving...</p>
          </div>
        </div>
      )}

      <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">New Scooter Rental</h1>
        {orderSaved && (
          <p className="mb-4 text-center text-green-600">Order saved successfully!</p>
        )}

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Number of Scooters</label>
          <select
            value={scooterCount}
            onChange={handleScooterCountChange}
            disabled={!chooseCount}
            className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
          >
            {[1, 2, 3, 4, 5].map((count) => (
              <option key={count} value={count}>
                {count}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Customer Mobile Number</label>
          <input
            type="text"
            value={customerMobile}
            onChange={handleMobileChange}
            placeholder="+971 123456789"
            className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
          />
          <button
            onClick={checkCustomer}
            className="mt-2 w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-200"
          >
            Check Customer
          </button>
        </div>

        {showCustomerForm && (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                value={newCustomer.name}
                onChange={(e) => setNewCustomer({ ...newCustomer, name: e.target.value })}
                className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Home Address</label>
              <input
                type="text"
                value={newCustomer.home_address}
                onChange={(e) => setNewCustomer({ ...newCustomer, home_address: e.target.value })}
                className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
              />
            </div>

            {/* <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Government ID Picture</label>
              <input
                type="file"
                onChange={handleGovtIdPicUpload}
                className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
              />
            </div> */}

<div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">Government ID Picture</label>
  
  {newCustomer.govt_id_pic ? (
    <img 
      src={newCustomer.govt_id_pic} 
      alt="Government ID" 
      className="w-full h-100 object-cover rounded-lg mb-2"
    />
  ) : (
    <p className="text-gray-500 mb-2">No ID picture available.</p>
  )}

  <input
    type="file"
    onChange={handleGovtIdPicUpload}
    className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
  />
</div>


            {otpSent && (
              <>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Enter OTP</label>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
                  />
                </div>
              </>
            )}
          </>
        )}

<div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">Select Scooters</label>
  {scooterFields.map((field, index) => (
    <div key={index} className="mb-2">
      <ReactSearchAutocomplete
        items={availableScooters.map((scooter) => ({
          ...scooter,
          id: scooter.scooter_id  // Explicitly setting 'id' for unique keys
        }))}
        onSelect={(item) => handleScooterSelect(index, item)} // Pass selected scooter details
        inputDebounce={300}
        styling={{ zIndex: 10 - index }}
        placeholder="Select scooter number"
        showIcon={false}
        resultStringKeyName="name"
      />
    </div>
  ))}
</div>


{/* Terms and Conditions Checkbox */}
<div className="mb-4 flex items-center">
        <input
          type="checkbox"
          checked={termsAccepted}
          onChange={handleTermsChange}
          className="mr-2"
        />
        <label className="text-sm text-gray-700">I accept the Terms and Conditions</label>
      </div>

      {/* Terms Modal */}
      {showTermsModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/2">
            <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>
            <p className="mb-4 scrollable-paragraph">
              {/* Long paragraph with terms and conditions goes here */}
              כתב שחרור מאחריות (ויתור על תביעות)

על ידי חתימתך על מסמך זה הנך מוותר/ת בזאת על כל זכות, מכל מן וסוג שהיא, לרבות על הזכות לתבוע ו/או על הזכות לשיפוי אשר עשויה להיווצר בגין השכרת כלי הנהיגה כאמור להלן, הכרוכה בסיכונים ובחוסר נוחות. ובידוע שכל העושה בהם שימוש עלול להיפצע ו/או להיפגע פיזית ו/או להינזק כלכלית ו/או נפשית ו/או פיזית וכו'. מסמך זה מהווה "הסכמה לנטילת סיכון" ו/או "כתב ויתור ושחרור", תוקפו משפטי ומחייב ויש לקראו בעיון רב.

נטילת כל אחד מהדו גלגליים שלהלן: קורקינט חשמלי, אופניים חשמליים (להלן: "הדו גלגלי" ו/או "המושכר"), מידי קורקילנד "השכרת כלים חשמליים" ע.פ. 206929028 ו/או מי מטעמו (להלן: "המשכיר"), לצורך עשיית שימוש בו, בין היתר על ידי הפעלתו ו/או רכיבה עליו, כרוכה בסיכון. מסמך זה מתייחס ויכלול את כל הפעילויות, האירועים או השירותים הניתנים, מוסדרים , מאורגנים, מאושרים על ידי המשכיר הכוללים שימוש במושכר בין אם במהלך הרכיבה עצמה על הכלי ובין אם במהלך נטילתו והחזרתו.

לכן אני הח"מ , (להלן: "השוכר/ת"), מצהיר בזאת ומאשר כדלקמן:

אני שוכר את המושכר לשימושי האישי בלבד והנני מצהיר כי לי הידע ו/או היכולת ו/או המיומנות וכו' להפעיל את המושכר בצורה הטובה ביותר תוך שמירה על כללי הנהיגה ו/או על בריאותי.
כל שימוש שיעשה במושכר, שלא על ידי יהא באחריותי בלבד, על כל המשתמע מכך, בין אם שימוש כאמור יעשה על ידי מי מטעמי ו/או שלא מטעמי.
כל זמן שהמושכר נמצא בחזקתי ו/או בחזקת מי מטעמי, הנני אחראי לשמירת תקינותו ו/או תפעולו הראוי של המושכר ו/או לשלומי הבריאותי ו/או לשלום הבריאות של המשתמש מטעמי.
הנני מודע/ת כי השימוש בכלים מסוג המושכר טרם הוסדר רגולטורית בענף הביטוח, וכי קיימים סיכונים לעושים בו שימוש וכי קיימת רמת חוסר נוחות הכרוכה בשימוש ו/או הפעלת המושכר, על כל המשתמע מכך.
הנני מצהיר/ה כי אני בריא/ה פיזית ונפשית והנני יודע/ת ומבין/נה כי שימוש במושכר, כרוכה בין היתר במאמץ גופני ואשר כשל במצבי זה עלול לגרום או להחמיר פציעות ו/או להחמיר את מצבי הבריאותי.
אני גם מבין/נה ומסכים/מה כי תנאי מזג האוויר ו/או גורמים בלתי צפויים אחרים עלולים להשפיע על רמת הבטיחות של השימוש במושכר ואני מקבל על עצמי את האחריות במצבים כאלה ומתחייב לשאת בכל הנזקים ו/או העלויות שיגרמו על ידי שימוש ו/או הפעלת המושכר ו/או שיגרמו לכל צד ג' מטעמי שיעשה שימש במושכר.
הנני מבין ויודע את המשמעויות של אופי המסוכן של השימוש ו/או הפעלת המושכר והריני מצהיר כי לא קיבלתי כל הנחיה ו/או הוראה מרופא המשפחה ו/או כל רופא להימנע מפעילות גופנית הכרוכה בהפעלתו של המושכר.
הנני מצהיר שהתכוננתי לקראת השימוש במושכר, הן בלימוד אופיו, והן ברכישת ידע הנחוץ לקראת השימוש בו ו/או הפעלתו והן באימונים פיזיולוגיים להכשרת הגוף לקראת השימוש ו/או הפעלת הדו גלגלי.
הנני מאשר ומבין שיש סיכונים אינהרנטיים וסיכונים אחרים המעורבים בסוג כזה של פעילות ואני באופן חופשי ומרצוני האישי בוחר לקחת סיכונים אלו וחוסר נוחות הכרוך לשימוש ו/או הפעלת הדו גלגלי.
הנני משחרר/ת בזה ו/או פוטר/ת מכל אחריות את המשכיר ו/או את עובדיו ו/או כל מי שמטעמו (להלן: "הצדדים המשוחררים") מכל אחריות ו/או תביעה ו/או דרישה ו/או עילה משפטית העשויים להיות לי בגין מוות, פציעות, כול נזק או אובדן כלשהו, באם יקרו לי כתוצאה מהשימוש במושכר , בין אם נגרם בהתאם להוראות שקיבלתי ובין אם לאו, וכן נזקים כאמור שספגתי כתוצאה ממעשה או מחדל של הצדדים המשוחררים ולרבות רשלנותם.
הנני מסכים/מה ומתחייב/ת שלא אדרוש ולא אגיש תביעה כנגד הצדדים המשוחררים בגין נזק או אובדן ו/או כל נזק אחר שנגרם לי ואני מסכים/מה לפצות ולשפות את הצדדים המשוחררים בגין כל חיוב שיושת עליהם, לרבות הוצאות ושכר טרחת עורכי דין, הנובע במישרין או בעקיפין ולרבות בגין רשלנותם של הצדדים המשוחררים.
אני לבדי אהיה אחראי/ת לכל נזק ו/או אובדן ו/או תאונה ו/או פציעה מכל מין וסוג ישירים ו/או עקיפים ו/או תוצאתיים, שיגרמו לגוף ו/או לרכוש, לרבות לי ו/או לכל דבר ו/או גורם ו/או צד שלישי אחרים מטעמי.
מבלי לגרוע מכלליות האמור אין ולא תהיה לי כל טענה ו/או תביעה ו/או דרישה, מכל מין וסוג, כלפי המארגן ו/או מקדמיו ו/או מממניו לרבות מי מפקידיהם ו/או סוכניהם ו/או נציגיהם, זאת אף אם ניתן לייחס למי מהם ו/או לכל גורם אחר רשלנות ו/או מחדל.
הריני מתחייב/ת כי במקרה ובהליך משפטי כלשהו בו יקבע כי רשלנותם של הצדדים המשוחררים גרמה למותי, פציעתי או נזקיי האחרים, אזי אהיה אחראי/ת לפצות ולשפות את הצדדים המשוחררים בגין כל סכום שיפסק כנגדם במסגרת הליך כאמור.
הנני לאשר כי הובהר לי והבנתי היטב שהשימוש במושכר כרוך בסיכונים ובסכנות שגם זהירות ו/או השגחה ו/או הכשרה ו/או הדרכה ו/או מומחיות, אינם יכולים למנוע ועל כן הנני נוטל/ת על עצמי באופן מפורש ומרצון את כל הסיכונים שיגרמו לי ו/או למשתמשים  מטעמי לפגיעה ו/או לפציעה ו/או מוות, נזק גופני ואבדן כלכלי שעלולים להיגרם לי, בין אם נגרמו ברשלנותם של הצדדים המשוחררים ובין אם לא.
הנני מצהיר כי בדקתי את תקינותו של המושכר וכי מצאתי אותו תקין לשימוש וכי אני מוותר בזאת על כל טענה כנגד המשכיר בגין אי תקינות הכלי לרבות כל הנובע מכך.
הנני מצהיר/ה כי אני בריא/ה וכשיר/ה לחתום על הסכם זה וכי כתב התחייבותי זה תופס לשלושה חודשים ממועד חתמתי ויהא תקף אף להשכרת המושכר לשימושים חוזרים במהלך תקופה זו בין אם על ידי ובין אם לכל המורשה מטעמי.
הנני מצהיר/ה כי הינני מעל גיל 16 וכי עמדתי בדרישות החוק ועברתי מבחן תיאוריה או בחינה ייעודית לרכיבה וקיבלתי אישור הכשרה לרכיבה. כמו כן, הנני מתחייב שלא להעביר את המושכר, לשימוש של אדם אחר שאינו עומד בתנאים הנ"ל.
קראתי את כל האמור בהסכם זה, הנני מבין/נה את תכנו הבנה מלאה והנני מסכים/מה באישור התשלום להיות מחויב/ת על ידו.
 הנני מודע לאפשרות שאקבל דוח בעת נסיעתי במושכר שלא כחוק ואני לוקח את מלוא האחריות על הכלי הן מבחינה חוקית והן מבחינה הלכתית ומאשר שכל דוח שיגיע ע''י צילום או כל דרך אחרת בזמן שהמושכר היה בידי או ביד מי מטעמי יעבור על שמי ללא ויכוח ויגבה עמלת טיפול של 50 ש''ח

            </p>
            <div className="border border-gray-300 p-2 mb-4">
            <SignatureCanvas
          ref={signatureCanvasRef}
          penColor="black"
          canvasProps={{
            width: 500,
            height: 200,
            className: 'w-full h-48 border rounded cursor-crosshair'
          }}
          onBegin={() => setError('')}
        />
        
        {error && (
          <div className="text-red-500 text-sm mt-2">{error}</div>
        )}
            </div>
            <button
              onClick={handleSignature}
              className="bg-blue-600 text-white px-4 py-2 rounded"
            >
              Accept and Sign
            </button>
            <button
              onClick={handleResetSign}
              className="bg-red-500 text-white px-4 py-2 rounded ml-4 mr-4"
            >
             Reset Sign
            </button>
            <button
              onClick={() => setShowTermsModal(false)}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Display Signature */}
      {signature && (
        <div className="mb-4">
          <h3 className="font-bold">Your Signature:</h3>
          <img src={signature} alt="Signature" className="mt-2" />
        </div>
      )}

      {/* Save Rental Button */}
      <button
        onClick={handleSubmit}
        disabled={!termsAccepted || loading}
        className={`w-full py-2 px-4 rounded-lg transition duration-200 mt-4 ${
          !termsAccepted || loading ? "bg-gray-500 cursor-not-allowed" : "bg-green-500 hover:bg-green-600 text-white"
        }`}
      >
        Save Rental
      </button>
        <button
          onClick={handleReset}
          className="w-full bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition duration-200 mt-2"
        >
          Reset
        </button>

        <div ref={recaptchaContainerRef} />
      </div>
    </div>
  );
};

export default NewRental;
