import React, { useState, useEffect } from "react";
import axios from "axios";
import config from '../config';

const PastRentals = () => {
  const [rentals, setRentals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchPastRentals = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api/orders/past-rentals`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Pass the token in the headers
          },
        });
        setRentals(response.data); // Assuming response.data contains the array of rental orders
      } catch (err) {
        setError("Failed to fetch past rentals. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchPastRentals();
  }, [token]);

  const formatDateTime = (dateString) => {
    if (!dateString) {
      return "Not Available"; // Handle null or empty date
    }
  
    const date = new Date(dateString);
  
    const pad = (num) => num.toString().padStart(2, "0");
  
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
  
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
  
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };
  
  

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Past Rentals</h1>
      
      {loading ? (
        <p className="text-gray-500">Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : rentals.length > 0 ? (
        <table className="w-full bg-white shadow-md rounded border border-gray-200">
          <thead className="bg-gray-100 border-b border-gray-300">
            <tr>
              <th className="text-left py-2 px-4">Customer Name</th>
              <th className="text-left py-2 px-4">Scooter Count</th>
              <th className="text-left py-2 px-4">Start Date</th>
              <th className="text-left py-2 px-4">End Date</th>
              <th className="text-left py-2 px-4">Status</th>
              <th className="text-left py-2 px-4">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {rentals.map((rental) => (
              <tr key={rental.order_id} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{rental.customer_name}</td>
                <td className="py-2 px-4">{rental.scooter_count}</td>
                <td className="py-2 px-4">{formatDateTime(rental.start_time)}</td>
                <td className="py-2 px-4">{formatDateTime(rental.end_time)}</td>
                <td className="py-2 px-4">{rental.status}</td>
                <td className="py-2 px-4">${rental.total_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-500">No past rentals found.</p>
      )}
    </div>
  );
};

export default PastRentals;
